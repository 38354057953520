@import '../node_modules/@syncfusion/ej2-base/styles/fabric.css';

@import '../node_modules/@syncfusion/ej2-buttons/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/fabric.css';
@import "../node_modules/@syncfusion/ej2-querybuilder/styles/fabric.css";
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/fabric.css';
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/fabric.css";

@import "../node_modules/@syncfusion/ej2-react-buttons/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-react-grids/styles/fabric.css";
@import '../node_modules/@syncfusion/ej2-react-inplace-editor/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-react-inputs/styles/fabric.css';
/* @import "../node_modules/@syncfusion/ej2-react-lists/styles/fabric.css"; */
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/fabric.css';
@import '../node_modules/@syncfusion/ej2-react-notifications/styles/fabric.css';
@import "../node_modules/@syncfusion/ej2-react-popups/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/fabric.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}